import React from "react";
import logo from "../protoduck_logo.png";
import "../App.css";
import styled from "styled-components";

import { PageWrapperDiv } from "../styled/styled";

const CenteredDiv = styled.div`
  text-align: center;
`;

const CenteredP = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: normal;
  font-family: Aspirin, sans-serif;
`;

const LogoImage = styled.img`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Home = () => {
  return (
    <PageWrapperDiv>
      <CenteredDiv>
        <LogoImage src={logo} className="App-logo" alt="logo" />
        <CenteredP>Välkommen till Protoduck.</CenteredP>
      </CenteredDiv>
    </PageWrapperDiv>
  );
};

export default Home;
