import React from "react";
import "../App.css";
import "./Contact.module.css";

import { SubHeader, PageWrapperDiv } from "../styled/styled";

const Contact = () => {
  return (
    <PageWrapperDiv>
      <h1>Kontakta Protoduck</h1>
      <p>
        E-post: <a href="mailto: quack@protoduck.se"> quack@protoduck.se</a>
      </p>
      <p>
        Telefon: <a href="tel:+46703060418">+46 703060418</a>
      </p>

      <p>
        <SubHeader>Postadress</SubHeader>
        Protoduck i Dalarna AB
        <br />
        Svetsgränd 5<br />
        781 72 Borlänge
        <br />
        Sweden
        <br />
      </p>
    </PageWrapperDiv>
  );
};

export default Contact;
