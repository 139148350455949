
import React from "react";
import "../App.css";

import { SubHeader, PageWrapperDiv, StyledHeader } from "../styled/styled";

const About = () => {
    return (
        <PageWrapperDiv>
            <StyledHeader>
                Om Protoduck
            </StyledHeader>
            <p>
                Protoduck, baserat i Borlänge, Sverige, är din pålitliga partner för banbrytande elektroniska prototyper. Medan vi kallar Borlänge hem, sträcker sig vår räckvidd långt bortom geografiska gränser. Vi utnyttjar kraften i fjärrsamarbete för att betjäna kunder från alla hörn av världen.
            </p>
            <SubHeader>Vision</SubHeader>
            <p>

                Med ett åtagande om excellens och innovation förverkligar vi dina elektroniska idéer, oavsett var du befinner dig. Vårt dedikerade team är utrustat för att arbeta sömlöst och effektivt, vilket säkerställer att din vision blir en konkret verklighet, oavsett plats. På Protoduck bygger vi broar mellan kreativitet och avstånd och låter elektroniska drömmar bli verklighet över hela världen.

            </p>
            <SubHeader>
                Protoduck - Din partner för innovativa elektroniklösningar</SubHeader>
            <p>
                På Protoduck brinner vi för att förvandla dina idéer till verklighet. Vi specialiserar oss på att utveckla elektroniska prototyper, ta dem från koncept till prototyp och guida dem hela vägen till fullskalig produktion. Vårt omfattande utbud av tjänster omfattar inte bara de tekniska aspekterna utan också vårt orubbliga åtagande för hållbarhet.
            </p>
            <SubHeader>
                Vårt tillvägagångssätt: Från idéer till hållbar verklighet</SubHeader>

            <p>
                Hjärtat i vår filosofi är att driva dina idéer till konkreta elektroniska prototyper. Vi förstår resan från koncept till marknad, och det är därför vi finns här för att stödja dig vid varje steg. Vårt team av skickliga ingenjörer och designers (Johan, Karl och Elin) samarbetar nära med dig för att förverkliga

            </p>
            <SubHeader>
                Hållbar spelar roll</SubHeader>

            <p>
                Vi erkänner vikten av ansvarsfull elektronikutveckling. Därför väljer vi noggrant komponenter som uppfyller de högsta hållbarhetsstandarderna. Från idé till produktion är varje fas infunderad med miljömedvetna beslut, vilket säkerställer att dina produkter inte bara är toppmoderna utan också miljövänliga.

            </p>
        </PageWrapperDiv>
    );
};

export default About;