
import React from "react";
import "../App.css";


import { SubHeader, PageWrapperDiv, StyledHeader } from "../styled/styled";

const OurServices = () => {
    return (
        <PageWrapperDiv>
            <StyledHeader>
                Våra tjänster
            </StyledHeader>

            <p>
                Vi gör lite olika saker.
            </p>

            <p>
                <SubHeader>Elektronik</SubHeader>
                Vår huvudinriktning är att ta fram skräddarsydd elektronik med fokus på informationsbearbetning.
                Vi tar fram prototyper och ser till att allt fungerar som det ska.
            </p>

            <p>
                <SubHeader>Produktionsverktyg</SubHeader>
                Vi designar verktygen som krävs för att massproducera era produkter.
            </p>

            <p>
                <SubHeader>Testutrustning</SubHeader>
                Vi designar och bygger automatisk testutrustning för att testa era kretskort och produkter. Även med integrationer mot andra system
                för att göra en sömlös testprocess.
            </p>

            <p>
                <SubHeader>3D modellering</SubHeader>
                Vi tar fram fram 3D modeller som underlag för produktion.
            </p>

            <p>
                <SubHeader>3D-utskrifter</SubHeader>
                Vi har en 3D-skrivarfarm med flera olika skrivare och material. Vi kan skriva ut prototyper och ser till att allt blir som ni tänkt er.
            </p>

            <p>
                <SubHeader>CNC-fräsning</SubHeader>
                Vi kan fräsa ut detaljer i plast och metall för prototyper eller mindre produktion.
            </p>

            <p>
                <SubHeader>Third line support</SubHeader>
                Vi kan ta hand om den tekniska supporten för era produkter där det krävs djupare teknisk kunskap kring
                just er lösning.
            </p>
        </PageWrapperDiv >
    );
};

export default OurServices;