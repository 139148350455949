import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import OurServices from "./pages/OurServices";
import HowWeWork from "./pages/HowWeWork";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Navbar />

      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/HowWeWork" element={<HowWeWork />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
