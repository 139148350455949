// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  background-color: #282c34;

  scroll-behavior: smooth;
  /* overflow: hidden; */
  scrollbar-color: orange;
  scrollbar-width: thin;
  scrollbar-color: orange;
  min-height: 100vh;
  color: #ffffff;
  padding-bottom: 20vh;
  /* display: flex; */
}

.App {
  text-align: center;
  min-height: 10vmin;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  /* display: flex; */
  /* justify-content: justify; */

  text-align: justify;
  /* width: 50vw; */
  padding-bottom: 10px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }v
} */

.App-header {
  min-height: 5vh;
  background-color: #282c34;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f3c05b;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media screen and (max-width: 780px) {
  p {
    text-align: justify;

    padding-bottom: 10px;
  }

  h2 {
    margin-left: 10%;
    margin-right: 10%;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;;EAEzB,uBAAuB;EACvB,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,uBAAuB;EACvB,iBAAiB;EACjB,cAAc;EACd,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;;EAE9B,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;;;;GAIG;;AAEH;EACE,eAAe;EACf,yBAAyB;EACzB,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;;;;;;;GAOG;;AAEH;EACE;IACE,mBAAmB;;IAEnB,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":["html {\n  background-color: #282c34;\n\n  scroll-behavior: smooth;\n  /* overflow: hidden; */\n  scrollbar-color: orange;\n  scrollbar-width: thin;\n  scrollbar-color: orange;\n  min-height: 100vh;\n  color: #ffffff;\n  padding-bottom: 20vh;\n  /* display: flex; */\n}\n\n.App {\n  text-align: center;\n  min-height: 10vmin;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\np {\n  /* display: flex; */\n  /* justify-content: justify; */\n\n  text-align: justify;\n  /* width: 50vw; */\n  padding-bottom: 10px;\n}\n\n/* @media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }v\n} */\n\n.App-header {\n  min-height: 5vh;\n  background-color: #282c34;\n  display: flex;\n  /* flex-direction: column; */\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #f3c05b;\n}\n\n/* @keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n} */\n\n@media screen and (max-width: 780px) {\n  p {\n    text-align: justify;\n\n    padding-bottom: 10px;\n  }\n\n  h2 {\n    margin-left: 10%;\n    margin-right: 10%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
