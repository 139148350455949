import styled from "styled-components";

export const StyledHeader = styled.h1`
    
    font-size: 2rem;
    text-align: left;
    margin: 1rem 0;
`;

export const SubHeader = styled.h2`
    font-size: 1.5rem;
`;

export const PageWrapperDiv = styled.div` 
    margin: 0 auto;
    max-width: 800px;
    padding: 1rem;
    text-align: left;
`;