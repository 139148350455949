
import React from "react";
import "../App.css";
import Workflow from "../img/Workflow.png";

import { SubHeader, PageWrapperDiv, StyledHeader } from "../styled/styled";


const HowWeWork = () => {
    return (
        <PageWrapperDiv>
            <StyledHeader>
                Hur vi jobbar
            </StyledHeader>

            <p>
                Vi hänger med från idé till färdig produkt. Här är en snabb sammanfattning av hur vi jobbar.
            </p>

            <img src={Workflow} alt="Workflow" style={{ width: "100%" }} />

            <p>
                <SubHeader>Idé</SubHeader>
                Oftast börjar det med en bra idé. Vi vill vara med från starten och hjälpa till att visionera och skapa en plan för hur vi ska nå målet. Vi bollar idéer och undersöker möjligheter tillsammans med er.
            </p>

            <p>
                <SubHeader>Laboration</SubHeader>
                Att misslyckas tidigt är att lyckas snabbare. Vi bygger prototyper och testar dem för att se vad som fungerar och vad som inte fungerar. Vi lär oss av varje iteration och förbättrar och finjusterar.
            </p>

            <p>
                <SubHeader>Ritningar</SubHeader>
                Vi tar fram nödvändigt tråkigt produktionsmaterial och ser till att beställer mönsterkort och komponenter.
            </p>

            <p>
                <SubHeader>Prototyper</SubHeader>
                Vi bygger och testar produkten. Vi ser till att allt fungerar som det ska och att produkten lever upp till era krav och förväntningar.
                Vi tar fram verktygen som behövs för produktion och sköter även kapsling och kablage om ni så önskar.
            </p>

            <p>
                <SubHeader>Webtjänster</SubHeader>
                De flesta produkter kräver en eller annan form av webbtjänst. Vi bygger webbgränssnitt och molntjänster som passar just er produkt
                och som integrerar mot era system.
            </p>

            <p>
                <SubHeader>Produktion</SubHeader>
                Även produktion i mindre skala kan vi lösa alternativt ta fram produktionsunderlag för att outsourca produktionen.
            </p>
        </PageWrapperDiv >
    );
};

export default HowWeWork;